body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 
.main2-sidebar2 a:-moz-focusring {
	border: 0;
	outline: 0
}

.scrollb::-webkit-scrollbar {
	width: .5rem;
	height: .5rem
}

.scrollb::-webkit-scrollbar-thumb {
	background-color: #a9a9a9
}

.scrollb::-webkit-scrollbar-track {
	background-color: transparent
}

.scrollb::-webkit-scrollbar-corner {
	background-color: transparent
}

.scrollb{
  overflow-y: auto;
  margin-top: 0;
  width:257px;
  height: calc(100% - (3.5rem + 1px));
	overflow-x: none;
	/*overflow-y: initial;*/
	padding-bottom: 0;
	padding-left: .4rem;
	padding-right: .5rem;
	padding-top: 0;
	background-image: url("./components/assets/plantilla/menu/FondoMenu.jpg");
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: thin;
	scrollbar-color: #a9a9a9 transparent
  
}


.FondoLogin {
	background-repeat: no-repeat; 
	background-position: center center;
	background-image: url("./components/assets/login/FondoLogin.jpg");
	
  }